import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading:false
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { loading: action.payload };
    },
    clearLoading: () => {
      return { loading: false };
    },
  },
});

const { reducer, actions } = loadingSlice;

export const {setLoading, clearLoading } = actions
export default reducer;