import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, CircularProgress } from '@mui/material';


import { verify,resend } from "../slices/auth";
import { clearMessage } from "../slices/message";
import showToast from "../common/ToastUtils";

const Login = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
const [username, setUsername] = useState(localStorage.getItem('username') ? JSON.parse(localStorage.getItem('username'))['email'] : '');
const [code, setCode] = useState('');
const [usernameError, setUsernameError] = useState('');
const [codeError, setCodeError] = useState('');
const [successful, setSuccessful] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);



  const handleSignIn = (formValue) => {
    // const {  code } = formValue;
    // console.log(formValue)
  // Reset previous error messages
  setUsernameError('');
  setCodeError('');

  // Validate username
  if (!username) {
    setUsernameError('Username is required');
    return;
  }

  // Validate password
  if (!code.trim()) {
    setCodeError('Code is required');
    return;
  }
    setLoading(true);
    setSuccessful(false);

    dispatch(verify({ email:username, code }))
      .unwrap()
      .then(() => {
        setSuccessful(true);

        navigate("/login");
        window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
  };

  const resendCode=()=>{
    if(!username.trim()){
        showToast('Please Enter User Name')
    }
    setLoading(true);
    setSuccessful(false);
    dispatch(resend({ email:username }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        setLoading(false)
        showToast('Code Sent Successfully',{type:'success'})
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
  }
  if (isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <Container maxWidth="xs">
    <Typography variant="h4" align="center" gutterBottom>
     {username? "OTP Verify":"Send OTP"}
    </Typography>
    <form >
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
          setUsernameError(''); // Clear the error message on change
        }}
        error={!!usernameError}
        helperText={usernameError}
        hidden={username}
      />
      <TextField
        label="code"
        type="text"
        variant="outlined"
        fullWidth
        margin="normal"
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
          setCodeError(''); 
        }}
        error={!!codeError}
        helperText={codeError}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSignIn}
      >
         {loading?<CircularProgress size="20px" color="secondary" />:    username?'Verify':'Resend Code'}
      </Button>
      <Button
        size="small"
        className="mt-4 "
        variant="outlined"
        color="secondary"
        style={{
            display:"flex",
            justifyContent:"center"
        }}
        onClick={resendCode}
      >
        Resend Code
      </Button>
    </form>
    {message && (
        <div className="form-group">
          <div
            className={successful ? "alert alert-success" : "text-danger"}
            role="alert"
          >
            {message}
          </div>
        </div>
      )}
  </Container>

  );
};

export default Login;
