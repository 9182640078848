import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { register } from "../slices/auth";
import {  useNavigate } from "react-router-dom";

import { clearMessage } from "../slices/message";
import { setLoading } from "../slices/loading";
import { CircularProgress, Container, TextField, Typography } from "@mui/material";
import showToast from "../common/ToastUtils";
import {  selectAppTypeSelector } from "../slices/appType";
const Register = () => {
  let navigate = useNavigate();

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  const {loading}= useSelector((state)=>state.loading)
  const dispatch = useDispatch();
  const selectedType = useSelector(selectAppTypeSelector);
  useEffect(() => {
    dispatch(clearMessage());

  }, [dispatch]);
  useEffect(()=>{
    if (message){
    showToast(message, { type: 'error' });
    }
  },[message])
  const initialValues = {
    username: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("This field is required!"),
  });

  const handleRegister = (formValue) => {
    const { username, email, password } = formValue;
    setSuccessful(false);
     dispatch(setLoading(true))
    dispatch(register({ username, email, password }))
      .unwrap()
      .then(() => {
         setSuccessful(true);
         dispatch(setLoading(false))
         if (selectedType==="transpiler"){
          showToast("You registered  Successfull",{type:"success",position:"top-right"})
          navigate('/login')
          return 
        }else{
          navigate('/otp-verify')
        }
        
      })
      .catch((err) => {
        dispatch(setLoading(false))
        setSuccessful(false);
      });
  };

  return (
    <Container maxWidth="xs" className="signup-form">
      <Typography variant="h4" className="text-center" component={'h4'} align="center" gutterBottom>
        Register
      </Typography>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            {!successful && (
              <div>
                <div className="my-2">
                  <Field as={TextField} fullWidth variant="outlined" label="Username" name="username" type="text"  />
                </div>
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-danger"
                  />

                <div className="my-2">
                  <Field as={TextField} fullWidth label="Email" variant="outlined" name="email" type="email"  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="my-2">
                  <Field
                  variant="outlined"
                  as={TextField}
                  fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    
                  />
                </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger"
                  />

                <div className="my-2">
                  <button type="submit" className="btn btn-primary btn-block">{loading? <CircularProgress size="20px" color="secondary" />:'Sign Up'} </button>
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>

      {message && (
        <div className="form-group">
          <div
            className={successful ? "alert alert-success" : "text-danger"}
            role="alert"
          >
            {message}
          </div>
        </div>
      )

      }
    </Container>
  );
};

export default Register;
