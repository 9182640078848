import React, {  useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  CssBaseline,
  Avatar,
} from "@material-ui/core";

import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import { logout } from "./slices/auth";
import EventBus from "./common/EventBus";
import OtpVerify from "./components/OtpVerify";
import NotFound from "./components/NotFound";
import ApplicationTypeSelection from "./components/ApplicationTypeSelection";
import { selectAppTypeSelector } from "./slices/appType";

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const selectedType = useSelector(selectAppTypeSelector);
  
  const logOut = useCallback(() => {
    window.location.reload()
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
       });

    return () => {
      EventBus.remove("logout");
    };
    
  }, [currentUser, logOut,message]);
  return (
    <Router>
      <div>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <Avatar
              alt="Corios Logo"
              src="https://www.coriosgroup.com/wp-content/themes/corios/images/corios-logo.png"
              style={{ marginRight: "8px" }}
            />
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              <Link to={"/"} style={{ color: "inherit", textDecoration: "none" }}>
                {selectedType?.toUpperCase() || 'Select Application Type'}
              </Link>
            </Typography>
            <div>
              {currentUser ? (
                <>
                  <Button color="inherit" component={Link} to={"/profile"}>
                    {currentUser.username}
                  </Button>
                  <Button color="inherit" onClick={logOut}>
                    Logout
                  </Button>
                </>
              ) :process.env.REACT_APP_SHOW_SIGNUP==='1' ?(
                <>
                  <Button color="inherit" component={Link} to={"/login"}>
                    Login
                  </Button>
                  <Button color="inherit" component={Link} to={"/register"}>
                    Sign Up
                  </Button>
                </>
              ):<></>}
            </div>
          </Toolbar>
        </AppBar>
        <Container style={{ marginTop: "20px" }}>
          <Routes>
            
            <Route path="/" element={ localStorage.getItem('appType')?<Login></Login>: <ApplicationTypeSelection />} />
            <Route path="/login" element={<Login />} />
           {
            <>
            process.env.REACT_APP_SHOW_SIGNUP=="1" &&
           <Route path="/register" element={<Register />} />
            <Route path="/otp-verify" element={<OtpVerify />} />
            </> }
            <Route path="/profile" element={<Profile />} />
            <Route path="*"  element={<NotFound/>}></Route>
          </Routes>
        </Container>
      </div>
    </Router>
  );
};

export default App;
