// ApplicationTypeSelection.js

import React, { useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppType, selectAppTypeSelector } from '../slices/appType';
import {  useNavigate } from "react-router-dom";

const ApplicationTypeSelection = () => {
  const dispatch = useDispatch();
  const selectedType = useSelector(selectAppTypeSelector);
  let navigate = useNavigate();

  const handleTypeSelection = (type) => {
    dispatch(selectAppType(type));
  };

  useEffect(()=>{
    localStorage.removeItem("appType")
  })
  const handleProceed = () => {
    // Perform actions based on the selected application type
    console.log('Selected Type:', selectedType);
    navigate('/login')
    
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Select Application Type
      </Typography>
      <Button
        variant={selectedType === 'transpiler' ? 'contained' : 'outlined'}
        onClick={() => handleTypeSelection('transpiler')}
      >
        Transpiler
      </Button>
      <Button
        variant={selectedType === 'scanner' ? 'contained' : 'outlined'}
        onClick={() => handleTypeSelection('scanner')}
      >
        Scanner
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!selectedType}
        onClick={handleProceed}
      >
        Proceed
      </Button>
    </Container>
  );
};

export default ApplicationTypeSelection;
