import { createSlice, createAsyncThunk,se } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import authHeader from "../services/auth-header";
import ParserService from "../services/parse.service";
import showToast from "../common/ToastUtils";

export const parse = createAsyncThunk(
    "scanner/",
    async ({formData}, thunkAPI) => {
      const appType=JSON.parse(localStorage.getItem('appType'))?.appType
      try {
        let customUrl=null
        if (appType==='transpiler'){
  customUrl=`https://5ndz0mcqlf.execute-api.us-west-2.amazonaws.com/transpiler/`
        }
        
        const { user } = thunkAPI.getState()?.auth
        const response = await ParserService.parser(formData,{ headers: { ...authHeader(user?.data)}},customUrl)
        thunkAPI.dispatch(setMessage(response.data.message));
        return response?.data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
      error.response.status===413?showToast(`The maximum allowable size for ${appType==='scanner'? `a zip file is 50 MB`:"a sas file is 10 MB"}`,{type:"error"})
         :showToast(error.response.status===404?'Some thing went wrong':message, { type: 'error' });

         return thunkAPI.rejectWithValue();

        // return thunkAPI.rejectWithValue();
      }
    }
  );
  export const checkStatus = createAsyncThunk(
    "/scanner/checkStatus",
    async (data, thunkAPI) => {
      try {
        const user=thunkAPI.getState().auth?.user
        const requestId=thunkAPI.getState().parser.data.requestId
        const response = await ParserService.checkStatus({requestId},{ headers: { ...authHeader(user?.data)}});
        thunkAPI.dispatch(setMessage(response.data.message));
        return response.data;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

  const initialState = {
    isParsed:false,
    successfullyCompiled:false,

  }
  const parserSlice = createSlice({
    name: "parser",
    initialState,
    reducers: {
      resetParser: (state, action) => {
        return {...action.payload };
      },
    },    
    extraReducers: {
      [parse.fulfilled]: (state, action) => {
        state.isParsed = true;
        state.data=action.payload
        state.successfullyCompiled=false
        state.retryCount=0.
         
    },
      [parse.rejected]: (state, action) => {
        state.isParsed = false;
        state.data=action?.payload?.data || null
        state.successfullyCompiled=false
        state.retryCount=0
   
      },
      [checkStatus.fulfilled]: (state, action) => {
        if (action?.payload?.error){
         showToast(action?.payload?.error,{type:"error",position:"top-right"}) 
        }else{
        showToast(`Processing Completed against requestId ${state.data.requestId}`,{type:"success",position:"top-right"})
        }
        state.isParsed = true;
        state.data=action?.payload?.error || state?.data
        state.successfullyCompiled=true
        state.retryCount=0

      },
      [checkStatus.rejected]: (state, action) => {
        state.isParsed = true;
        state.successfullyCompiled=false
        state.retryCount=state.retryCount+1


      }
    },
  });
  
  const { reducer,actions } = parserSlice;
  export const { resetParser } = actions

  export default reducer;
  