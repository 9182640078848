
export default function authHeader(user) {
   user =!user? JSON.parse(localStorage.getItem("user")):user
  console.log("sssssssssss",user)
  if (user && user.IdToken) {
    // For Spring Boot back-end
    return { Authorization: "Bearer " + user.IdToken };

    // for Node.js Express back-end
    // return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
