import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, CircularProgress } from '@mui/material';


import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";

const Login = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  useEffect(()=>{
    document.title = 'Corios | Login';
    return () => {
      document.title = 'Corios';
    };

  },[])

  const handleSignIn = (formValue) => {
  setUsernameError('');
  setPasswordError('');
 if (!username.trim()) {
    setUsernameError('Username is required');
    return;
  }
 if (!password.trim()) {
    setPasswordError('Password is required');
    return;
  }
    setLoading(true);
    dispatch(login({ email:username, password }))
      .unwrap()
      .then(() => {
        navigate("/profile");
        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  if (isLoggedIn) {

    return <Navigate to="/profile" />;
  }
  return (
    <Container maxWidth="xs">
    <Typography variant="h4" align="center" gutterBottom>
      Sign In
    </Typography>
    <form>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
          setUsernameError(''); // Clear the error message on change
        }}
        error={!!usernameError}
        helperText={usernameError}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setPasswordError(''); 
        }}
        error={!!passwordError}
        helperText={passwordError}
      />
      <Button
        variant="contained"
        color="primary"
        autoFocus    
        fullWidth
        onClick={handleSignIn}
      >
       {loading ? <CircularProgress size="20px" color="inherit" /> : 'Sign In'}
      </Button>
    </form>
  </Container>

  );
};

export default Login;
