import {  configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import loadingReducer from "./slices/loading"
import  parserReducer  from './slices/parser';
import authMiddleware from './middlewares/auth.middleware';
import appTypeReducer from './slices/appType';


const reducer = {
  auth: authReducer,
  message: messageReducer,
  loading:loadingReducer,
  parser:parserReducer,
  appType:appTypeReducer
}

const store = configureStore({
  reducer: reducer,
  middleware:[...getDefaultMiddleware(),authMiddleware],
  devTools: true,
})

 
export default store;
