// authMiddleware.js

import { logout } from '../slices/auth'; // Assuming you have an auth slice with a logout action

const authMiddleware = ({dispatch}) => (next) => (action) => {
  if (action.meta && action.meta.rejectedWithValue) {
    const status = action.payload.response && action.payload.response.status;
    if (status === 401) {
      dispatch(logout());
    }
  }
  next(action);
};

export default authMiddleware;
